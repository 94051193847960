class CookieStorage {
  public updated: number;
  public updatedLocal: number;

  constructor() {
    this.updated = Date.now();
    this.updatedLocal = Date.now();
  }

  private update() {
    this.updated = Date.now();
  }

  private updateLocal() {
    this.updatedLocal = Date.now();
  }

  private getExpirationDate(seconds: number) {
    let cookieDate = new Date();
    cookieDate.setSeconds(cookieDate.getSeconds() + seconds);
    return cookieDate;
  }

  save(key: any, value: any, expires?: number, raw?: boolean) {
    let exprationTimeInSeconds = expires != null ? expires : 900;
    let val = raw != null ? JSON.stringify(value) : btoa(JSON.stringify(value));

    let newCookie = `${key}=${val}`;
    newCookie += `; expires=${this.getExpirationDate(
      exprationTimeInSeconds
    ).toUTCString()}`;

    newCookie += "; path=/";

    if (window.location.protocol == "https:") {
      newCookie += "; secure";
    }

    document.cookie = newCookie;
    this.update();
  }

  delete(key: any) {
    document.cookie = key + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    this.update();
  }

  get(key: any, raw?: boolean, plain?: boolean) {
    var name = key + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        let val =
          raw != null
            ? c.substring(name.length, c.length)
            : atob(c.substring(name.length, c.length));
        return !plain ? JSON.parse(val) : val;
      }
    }
    return null;
  }

  saveLocalEncoded(key: any, value: any) {
    this.saveLocal(key, value, false, true);
  }

  saveSessionEncoded(key: any, value: any) {
    this.saveLocal(key, value, true, true);
  }

  saveLocal(key: any, value: any, session: boolean = false, encode?: boolean) {
    let valueToSave = null;

    try {
      valueToSave = encode
        ? btoa(JSON.stringify(value))
        : JSON.stringify(value);
    } catch (e) {
      const jsonValue = JSON.stringify(value);

      valueToSave = btoa(
        jsonValue.replace(
          /[\u00A0-\u2666]/g,
          (c: string) => `&#${c.charCodeAt(0)};`
        )
      );
    }

    if (session) {
      sessionStorage.setItem(key, valueToSave);
    } else {
      localStorage.setItem(key, valueToSave);
    }
    this.updateLocal();
  }

  getLocalEncoded(key: any) {
    return this.getLocal(key, false, true);
  }

  getSessionEncoded(key: any) {
    return this.getLocal(key, true, true);
  }

  getLocal(key: any, session: boolean = false, decode?: boolean) {
    let value = session
      ? sessionStorage.getItem(key)
      : localStorage.getItem(key);
    if (value != null) {
      let valueToReturn = decode ? atob(value) : value;
      return JSON.parse(valueToReturn);
    }
    return null;
  }

  deleteLocal(key: any, session: boolean = false) {
    if (session) {
      sessionStorage.removeItem(key);
    } else {
      localStorage.removeItem(key);
    }
    this.updateLocal();
  }
}

export let Storage = new CookieStorage();
